import axios from 'axios'
import { APIPath } from 'baserURL'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { VscError } from "react-icons/vsc";
import { BiSolidError } from 'react-icons/bi';
import DivLoader from 'components/divloader/DivLoader';
import parse from 'html-react-parser';

function PaymentRedirectPage() {
    const [isLoading, setLoading] = useState(true)
    const [isReload, setisReload] = useState(false)
    const [paymentUrl, setpaymentUrl] = useState("")
    const { gatewayid, internalid } = useParams()
    const [ isHTML, setIsHTML ] = useState(false)
    const navigate = useNavigate()
    const fetchPaymentURI = async () => {
        try {
            const apiurl = APIPath + `/api/v1/payment/redirect-uri/${gatewayid}/${internalid}`
            const res = await axios.get(apiurl)
            // console.log("res", res)
            if (res?.data?.redirect_uri_available) {
                setisReload(true)
                window.location.replace(res?.data?.redirect_uri)
            }
            if (res?.data?.redirect_html_available) {
                setIsHTML(true);
                // setpaymentUrl('<!DOCTYPE html><html lang="en"><head><title>Collector</title><meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"><meta http-equiv="X-UA-Compatible" content="IE=edge" /><link rel="stylesheet" href="/build/app.4316529d.css"></head><body><div class="d-flex justify-content-center align-items-center w-100" style="height: 100vh"><div class="spinner-border text-primary" role="status" style="width: 5rem; height: 5rem;"><span class="sr-only">Loading...</span></div></div><div id="logBodyContent"></div><form action="https://developer-api.neonpay.io/verify/014652f4-ab25-11ef-ab24-8a948bcb8d41/942f5569d5c5efd7c27260dd140398b3" method="post"></form><script>function submit(){const formElement=document.forms[0];const browserInfo={"color_depth":screen.colorDepth,"screen_height":screen.height,"screen_width":screen.width,"java_enabled":navigator.javaEnabled(),"javascript_enabled":true,"language":navigator.language,"timezone_offset":(new Date).getTimezoneOffset(),"user_agent":navigator.userAgent,"accept_headers":"*/*","platform":navigator.platform};let inputElement;for(const name in browserInfo){inputElement=document.createElement("input");inputElement.setAttribute("name",name);inputElement.setAttribute("value",browserInfo[name]);inputElement.setAttribute("type","hidden");formElement.appendChild(inputElement);}formElement.submit();}document.addEventListener("DOMContentLoaded",()=>submit());</script></body></html>')
                setpaymentUrl(res?.data?.html_structure)
            }

        } catch (error) {
            console.log("error", error)

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchPaymentURI()
    }, [])

    useEffect(() => {
        if (isHTML && paymentUrl) {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = paymentUrl;
    
            const logBodyContent = document.getElementById('logBodyContent');
            if (logBodyContent) {
                logBodyContent.innerHTML = tempDiv.querySelector('#logBodyContent')?.innerHTML || '';
            }
    
            const scripts = tempDiv.getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                const script = scripts[i];
                const newScript = document.createElement('script');
                newScript.type = script.type || 'text/javascript';
    
                if (script.src) {
                 
                    newScript.src = script.src;
                    newScript.onload = () => {
                        console.log(`Script loaded: ${script.src}`);
                        invokeSubmitIfAvailable();
                    };
                    document.body.appendChild(newScript);
                } else {
                
                    newScript.textContent = script.innerHTML;
                    document.body.appendChild(newScript);
    
                    invokeSubmitIfAvailable();
                }
            }
    
            tempDiv.remove(); 
        }
    }, [paymentUrl]);

    const invokeSubmitIfAvailable = () => {
        if (typeof window.submit === 'function') {
            console.log("Invoking submit function manually.");
            window.submit();
        } else {
            console.error("Submit function not available yet.");
        }
    };
    
    
    const Loader = () => {
        if (isLoading) {
            return <div className='mt-5 pt-5'>
                <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
                <p className='text-center'>Loading please wait...</p>

            </div>
        }
    }

    return (
        <div className='bg-white'>

            {
            isLoading 
            ? Loader() 
            : paymentUrl 
            ?<>
            <div className=' h-full bg-white'>
            <div
        dangerouslySetInnerHTML={{
            __html: paymentUrl.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, ''), // Remove <script> tags
          }}
      />
            </div>
            </>
            :isReload?<></>:
                <div className='h-screen flex flex-col justify-center'>
                    <div className='self-center '>
                        <BiSolidError className="mx-auto text-6xl text-red-500" />
                    </div>
                    <h5 className='self-center text-black-500 opacity-50'>Something went wrong, please check the url.</h5>
                </div>
            }
        </div>
    )
}

export default PaymentRedirectPage
