let APIPath: string;

if (window.location.hostname === "gateway-dev.lightningchecks.com") {
    // Lightning Checks DEV
    APIPath = "https://backend-dev.lightningchecks.com";
} else if (window.location.hostname === "gateway.lightningchecks.com") {
    // Lightning Checks PROD
    APIPath = "https://backend.lightningchecks.com";
} else if (window.location.hostname === "gateway-dev.bm2pay.com") {
    // Bm2Pay DEV
    APIPath = "https://backend-dev.bm2pay.com";
} else if (window.location.hostname === "gateway.bm2pay.com") {
    // Bm2Pay PROD
    APIPath = "https://backend.bm2pay.com";
} else if (window.location.hostname === "pay.paxum.com") {
    // Paxum
    APIPath = "https://backend.paxum.com";
} else if (window.location.hostname === "gateway-stagging.bm2pay.com") {
    // Staging Bm2Pay
    APIPath = "https://backend-stagging.bm2pay.com";
} else if (window.location.hostname === "testing.lightningchecks.com") {
    // Staging Bm2Pay
    APIPath = "https://testing2.lightningchecks.com";
} else if (window.location.hostname === "192.168.1.13") {
    // Local Testing
    APIPath = "http://192.168.1.13:5252";
} else {
    // Default fallback if none of the conditions match
    APIPath = "https://backend-dev.lightningchecks.com"; // or another default environment
}
// console.log("APIPath",APIPath,window.location.hostname);
export { APIPath };